import React, { useState } from 'react';
import block from 'bem-cn';
import Skeleton from 'react-loading-skeleton';
import { useSelector } from 'react-redux';
import { useHistory, useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AppFeatures, CreateAppModal, useGetActionByAccess } from '@features';
import { IconAddPlus, IconSelectArrowFresh, SearchSelect } from '@components';
import { FreshDrop } from '@components/_fresh';
import { RootState } from '../../../../store';
import './ChooseAppContainer.scss';

const b = block('ChooseAppContainer');

export const ChooseAppContainer: React.FC = () => {
  const history = useHistory();
  const location = useLocation();

  const state = useSelector((store: RootState) => store.appManager);

  const { t } = useTranslation();

  const [modalIsOpen, toggleModal] = useState(
    state.status === 'loaded' && state.appsList.length === 0
  );

  const onActionClick = useGetActionByAccess();

  const handleOpenAppModal = () => toggleModal(true);
  const handleCloseAppModal = () => toggleModal(false);

  const getNavigationPath = (appId: string) => {
    const path = location.pathname.split('/').filter((item) => item);

    const isTemplates = path[2] === 'templates';

    if (isTemplates) {
      return `/dashboard/${appId}/${path[2]}/${path.slice(3).join('/')}`;
    }

    return `/dashboard/${appId}/${path[2]}`;
  };

  const handleSelect = (value: string) => {
    history.push(getNavigationPath(value));
  };

  return (
    <>
      <div className={b()}>
        {state.status === 'pending' || !state.current ? (
          <div className={b('item')}>
            <Skeleton height={20} width={152} />
          </div>
        ) : (
          <>
            {state.appsList.length !== 0 && (
              <div className={b('item')}>
                <FreshDrop
                  control={
                    <button className={b('selectBtn')}>
                      <span className={b('selectBtnText')}>{state.current.title}</span>
                      <IconSelectArrowFresh className={b('selectBtnArrow').toString()} />
                    </button>
                  }
                  targetElementForClose=".SearchSelect__optionsItem"
                >
                  <SearchSelect
                    className={b('selectModal').toString()}
                    options={state.appsList.map((app) => ({
                      value: app.id,
                      label: app.title
                    }))}
                    selectedValue={state.current.id}
                    onChange={handleSelect}
                  />
                </FreshDrop>
              </div>
            )}
            <div className={b('item')}>
              <div
                className={b('control')}
                role="button"
                tabIndex={0}
                onClick={() => onActionClick(AppFeatures.CREATE_APP, handleOpenAppModal)}
                onKeyDown={() => onActionClick(AppFeatures.CREATE_APP, handleOpenAppModal)}
              >
                <span className={b('addIconWrapper')}>
                  <IconAddPlus className={b('addIcon').toString()} />
                </span>
                <span className={b('addText')}>{t('dashboard.header.addNewApp')}</span>
              </div>
            </div>
          </>
        )}
      </div>

      {modalIsOpen && <CreateAppModal isOpen onClose={handleCloseAppModal} />}
    </>
  );
};
