import React from 'react';
import block from 'bem-cn';
import { useTranslation } from 'react-i18next';
import { RootState } from '@store';
import { DateTime } from 'luxon';
import { useSelector } from 'react-redux';
import { FreshButton } from '@components/_fresh';
import { useGetUserSubscription } from '@hooks';
import { IconInfoWarning } from '@components';
import { formatNumber, getCurrencySymbol } from '@utils';
import './SubscriptionCard.scss';

const b = block('SubscriptionCard');

export const SubscriptionCard: React.FC = () => {
  const { t } = useTranslation();

  const userEmail = useSelector((state: RootState) => state.user.user.email);
  const userSubcription = useGetUserSubscription();

  const getSubscriptionStatus = () => {
    if (userSubcription.isTrialing) {
      return t('dashboard.billing.trial');
    }

    if (
      userSubcription?.subscriptionStatus === 'active' ||
      userSubcription?.subscriptionStatus === 'trialing'
    ) {
      return t('dashboard.billing.active');
    }

    if (userSubcription?.subscriptionStatus === 'past_due') {
      return t('dashboard.billing.pastDue');
    }

    return t('dashboard.billing.inactive');
  };

  return (
    <div className={b()}>
      <div className={b('titleContainer')}>
        <p className={b('title')}>{t('dashboard.billing.subscriptionDetails')}</p>
      </div>

      <div className={b('info')}>
        <p className={b('infoItem')}>
          {t('dashboard.billing.plan')}{' '}
          <span className={b('infoItemValue')}>
            {userSubcription.mauLimit ? formatNumber(userSubcription.mauLimit) : '∞'}{' '}
            {t('dashboard.usage.mau')}
          </span>
        </p>

        <p className={b('infoItem')}>
          {t('dashboard.billing.price')}{' '}
          <span className={b('infoItemValue')}>
            {userSubcription.price > 0 ? (
              <>
                {userSubcription.price / 100} {getCurrencySymbol(userSubcription.currency)} /
                {t('plans.month')}
              </>
            ) : (
              t('dashboard.billing.free')
            )}
          </span>
        </p>

        {!userSubcription.isTrialing && (
          <p className={b('infoItem')}>
            {t('dashboard.billing.nextPaymentIs')}{' '}
            <span className={b('infoItemValue')}>
              {userSubcription.subscriptionFinish && userSubcription.price > 0
                ? DateTime.fromSeconds(userSubcription.subscriptionFinish).toLocaleString({
                  month: 'long',
                  day: 'numeric',
                  year: 'numeric'
                })
                : '-'}
            </span>
          </p>
        )}

        <p className={b('infoItem')}>
          {t('dashboard.billing.paymentMethod')}{' '}
          <span className={b('infoItemValue')}>
            {userSubcription.price > 0 ? t('dashboard.billing.creditCard') : '-'}
          </span>
        </p>

        <p className={b('infoItem')}>
          {t('dashboard.billing.statusSubscription')}{' '}
          <span className={b('infoItemValue')}>{getSubscriptionStatus()}</span>
        </p>

        {userSubcription.isUnpaid && (
          <div className={b('infoItemContainer')}>
            <IconInfoWarning className={b('icon').toString()} />
            <p className={b('infoItem')}>
              {t('dashboard.billing.additional')}{' '}
              <span className={b('infoItemValue')}>{t('dashboard.billing.billingIssue')}</span>
            </p>
          </div>
        )}
      </div>

      <div className={b('buttonContainer')}>
        <FreshButton
          className={b('button')}
          size="md"
          text={t('dashboard.billing.goToBilling')}
          onClick={() => {
            window.open(
              `${process.env.REACT_APP_STRIPE_BILLING_LINK}?prefilled_email=${userEmail}`,
              '_blank'
            );
          }}
        />
      </div>
    </div>
  );
};
