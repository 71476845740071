import React from 'react';
import block from 'bem-cn';
import { useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { OrderSummaryTab } from '@modules/DashboardModule/components';
import { useSelector } from 'react-redux';
import { RootState } from '@store';
import { Tabs, TabType } from '@components';
import { AccountSettingsTab } from './components/AccountSettingsTab/AccountSettingsTab';
import { UsageTab } from './components/UsageTab/UsageTab';
import './AccountSettingsRoute.scss';

const b = block('AccountSettingsRoute');

export const AccountSettingsRoute: React.FC = () => {
  const { tab } = useParams<{ tab: 'settings' | 'usage' | 'billing' }>();
  const appsState = useSelector((store: RootState) => store.appManager);
  const user = useSelector((store: RootState) => store.user.user);

  const { t } = useTranslation();

  const tabsItems: TabType[] = [
    {
      id: 'settings',
      title: t('user.accountSettings'),
      component: AccountSettingsTab
    },
    {
      id: 'billing',
      title: t('user.billing'),
      component: OrderSummaryTab
    },
    {
      id: 'usage',
      title: t('user.usage'),
      component: UsageTab
    }
  ].filter((tabItem) => {
    if (
      tabItem.id === 'usage' &&
      (appsState.appsList.length === 0 ||
        !appsState.appsList.find((app) => app.ownerId === user.id))
    ) {
      return false;
    }
    return true;
  });

  return (
    <div className={b()}>
      <div className={b('tabs')}>
        <Tabs initialTabId={tab} tabs={tabsItems} />
      </div>
    </div>
  );
};
