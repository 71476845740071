import React, { memo, useMemo } from 'react';
import block from 'bem-cn';
import { useSelector } from 'react-redux';
import { RootState, useAppDispatch } from '@store';
import { useParams, Link } from 'react-router-dom';
import { appsManagerSlice } from '@features';
import { useTranslation } from 'react-i18next';
import { StoryEditorAction, useStoryEditorDispatch } from '@modules';
import { Drop } from '@custom';
import { Icon, IconExtLink, IconCheckDown, IconWarning } from '@components';
import './LocaleSelector.scss';

const b = block('LocaleSelector');

interface LocaleSelectorProps {
  theme?: 'light' | 'dark';
  isRight?: boolean;
  localesWithWarning?: string[];
}

export const LocaleSelector: React.FC<LocaleSelectorProps> = memo(
  ({ theme = 'dark', localesWithWarning, isRight }) => {
    const dispatch = useAppDispatch();
    const { appId } = useParams<{ groupId: string; appId: string }>();
    const app = useSelector((store: RootState) => store.appManager);
    const storyEditorDispatch = useStoryEditorDispatch();

    const currentLocale = useMemo(() => {
      if (app.locales) {
        return app.locales.find((item: any) => item.shortName === app.currentLocale);
      }

      return null;
    }, [app]);

    const { t } = useTranslation();

    const handleChange = (shortName: string) => {
      if (currentLocale?.shortName === shortName) return;

      storyEditorDispatch?.({
        type: StoryEditorAction.SET_SELECTED_WIDGETS_IDS,
        payload: []
      });

      storyEditorDispatch?.({
        type: StoryEditorAction.SET_STORY,
        payload: null
      });

      dispatch(appsManagerSlice.actions.setCurrentLocale(shortName));
    };

    const control = (
      <div className={b('btn', { theme })}>
        <div
          className={b('icon')}
          dangerouslySetInnerHTML={{ __html: currentLocale?.icon as string }}
        />
        <p className={b('title', { theme })}>{currentLocale?.name}</p>

        <Icon className={b('chevron').toString()} name="chevron-down-fresh" variant="icons" />
      </div>
    );

    if (currentLocale) {
      return (
        <div className={b()}>
          <Drop control={control} isRight={isRight}>
            <div className={b('menu')}>
              <Link className={b('menuManage')} to={`/dashboard/${appId}/settings/Localization`}>
                <p className={b('menuExtLink')}>{t('dashboard.header.manageLocalization')}</p>
                <IconExtLink className={b('menuExtIcon').toString()} />
              </Link>

              <div className={b('menuContent')}>
                {app.current?.appLocalization?.languages?.map((item: any) => (
                  <div
                    className={b('menuItem')}
                    key={`menuLocale-${item.shortName}`}
                    role="button"
                    tabIndex={0}
                    onClick={() => handleChange(item.shortName)}
                    onKeyDown={() => handleChange(item.shortName)}
                  >
                    <div
                      className={b('icon')}
                      dangerouslySetInnerHTML={{ __html: item?.icon ? (item.icon as string) : '' }}
                    />
                    <p className={b('menuItemTitle')}>
                      {item?.name ?? ''}{' '}
                      {localesWithWarning?.includes(item?.shortName) && (
                        <IconWarning className={b('warningIcon')} />
                      )}
                    </p>

                    {currentLocale.shortName === item?.shortName && (
                      <IconCheckDown className={b('checkIcon').toString()} />
                    )}
                  </div>
                ))}
              </div>
            </div>
          </Drop>
        </div>
      );
    }

    return null;
  }
);
