import React, { useEffect, useState, memo, useRef } from 'react';
import block from 'bem-cn';
import _ from 'lodash';
import { getWidgetPositionLimits } from '@features/stories/consts';
import { RootState, useAppDispatch } from '@store';
import { useSelector } from 'react-redux';
import { WidgetObjectTypeExtended, fetchUpdateApp } from '@features';
import { WidgetObjectType, WidgetsTypes } from '@storysdk/react';
import { useCurrentWidgetPosititon, useDebounce, useWidgetElementsSize } from '@hooks';
import { WidgetElementsSizes, renderWidgetControl } from '../../utils';
import { WidgetLines } from './_components/WidgetLines';
import { WidgetOutline } from './_components/WidgetLines/WidgetOutline';
import './WidgetFactory.scss';

const b = block('StoryObjectComponent');

interface WidgetFactoryProps {
  widget: WidgetObjectType;
  hoveredWidget?: WidgetObjectType;
  isSelected: boolean;
  isHovered: boolean;
  isAutoplayVideo?: boolean;
  isGroupMode?: boolean;
  presetHeight: number;
  presetWidth: number;
  scale?: number;
  isAltPressed?: boolean;
  hasSelected?: boolean;
  isEditWidgetMode?: boolean;
  onChangeWidget?(payload: WidgetObjectTypeExtended, isFirst?: boolean): void;
  onHover?(id: string | null): void;
  onRightClick?(id: string | null): void;
}

const widgetRememberTypes = [
  WidgetsTypes.RECTANGLE,
  WidgetsTypes.ELLIPSE,
  WidgetsTypes.TEXT,
  WidgetsTypes.CLICK_ME
];

const WidgetFactory: React.FC<WidgetFactoryProps> = memo((props) => {
  const {
    widget,
    hoveredWidget,
    isSelected,
    isHovered,
    hasSelected,
    isAltPressed,
    presetHeight,
    presetWidth,
    isGroupMode,
    isAutoplayVideo,
    isEditWidgetMode,
    scale,
    onChangeWidget,
    onHover,
    onRightClick
  } = props;

  const { id, positionByResolutions, content } = widget;

  const app = useSelector((state: RootState) => state.appManager);
  const dispatch = useAppDispatch();
  const widgetChanged = useDebounce(widget.content);
  const positionLimits = getWidgetPositionLimits(widget.content.type);
  const [isFirst, setIsFirst] = useState(true);
  const widgetRef = useRef<HTMLDivElement>(null);
  const user = useSelector((state: RootState) => state.user.user);

  const currentPosition = useCurrentWidgetPosititon(
    positionByResolutions,
    presetWidth,
    presetHeight
  );

  const [widgetSize, setWidgetSize] = useState({
    width: currentPosition.realWidth,
    height: currentPosition.realHeight
  });

  const [isFirstSizeSetting, setIsFirstSizeSetting] = useState(true);

  useEffect(() => {
    if (
      currentPosition.realHeight &&
      currentPosition.realWidth &&
      isFirstSizeSetting &&
      (widgetSize.width !== currentPosition.realWidth ||
        widgetSize.height !== currentPosition.realHeight)
    ) {
      setWidgetSize({
        width: currentPosition.realWidth,
        height: currentPosition.realHeight
      });

      setIsFirstSizeSetting(false);
    }
  }, [currentPosition]);

  useEffect(() => {
    if (!widgetRef.current) {
      return;
    }
    const resizeObserver = new ResizeObserver((entries) => {
      window.requestAnimationFrame((): void | undefined => {
        if (!Array.isArray(entries) || !entries.length || !widgetRef.current) {
          return;
        }

        if (
          widgetRef.current?.offsetWidth &&
          widgetRef.current?.offsetHeight &&
          (widgetRef.current?.offsetWidth !== widgetSize.width ||
            widgetRef.current?.offsetHeight !== widgetSize.height)
        ) {
          setWidgetSize((prevState) => ({
            ...prevState,
            width: widgetRef.current?.offsetWidth ?? currentPosition.realWidth,
            height: widgetRef.current?.offsetHeight ?? currentPosition.realHeight
          }));
        }
      });
    });

    resizeObserver.observe(widgetRef.current);
    // eslint-disable-next-line consistent-return
    return () => resizeObserver.disconnect();
  }, []);

  const handleHoverObject = () => {
    onHover && onHover(id);
  };

  const handleUnhoverObject = () => {
    onHover && onHover(null);
  };

  const handleContextMenu = (e: any) => {
    e.preventDefault();

    if (onRightClick) {
      onRightClick(id);
    }
  };

  const handleChangheWidgetContent = (newContent: WidgetObjectType['content']['params']) => {
    onChangeWidget?.({
      ...widget,
      content: { ...(widget.content as any), params: newContent }
    });
  };

  useEffect(() => {
    if (
      !isFirst &&
      isSelected &&
      widgetRememberTypes.includes(widgetChanged.type) &&
      user.id === app?.current?.ownerId
    ) {
      if (
        app?.current &&
        !_.isEqual(app.current.settings?.defaultWidgetsContent?.[widgetChanged.type], widgetChanged)
      ) {
        dispatch(
          fetchUpdateApp({
            id: app.current.id,
            settings: {
              ...app.current.settings,
              defaultWidgetsContent: {
                ...app.current?.settings?.defaultWidgetsContent,
                [widgetChanged.type]: widgetChanged
              }
            },
            noNotification: true
          })
        );
      }
    } else {
      setIsFirst(false);
    }
  }, [widgetChanged]);

  const elementsSize = useWidgetElementsSize(widget, widgetSize, currentPosition.elementsSize);
  const elementsSizeDebounce = useDebounce(elementsSize);
  // const widgetSizeDebounce = useDebounce(widgetSize);

  useEffect(() => {
    if (!elementsSizeDebounce) return;

    const newPosition = { ...currentPosition };

    if (widgetSize.width) {
      newPosition.realWidth = widgetSize.width;
    }

    if (widgetSize.height) {
      newPosition.realHeight = widgetSize.height;
    }

    onChangeWidget?.(
      {
        ...widget,
        position: { ...newPosition, elementsSize: elementsSizeDebounce }
      },
      true
    );
  }, [elementsSizeDebounce]);

  // useEffect(() => {
  //   if (
  //     elementsSizeDebounce ||
  //     (!widgetSizeDebounce.width && !widgetSizeDebounce.height) ||
  //     (widgetSizeDebounce.width === currentPosition.realWidth &&
  //       widgetSizeDebounce.height === currentPosition.realHeight)
  //   )
  //     return;

  //   const newPosition = { ...currentPosition };

  //   if (widgetSize.width) {
  //     newPosition.realWidth = widgetSizeDebounce.width;
  //   }

  //   if (widgetSize.height) {
  //     newPosition.realHeight = widgetSizeDebounce.height;
  //   }

  //   onChangeWidget?.(
  //     {
  //       ...widget,
  //       position: newPosition
  //     },
  //     true
  //   );
  // }, [widgetSizeDebounce]);

  if (widget.content.type in WidgetElementsSizes && !elementsSize) return null;

  return (
    <div
      className={b({
        isSelected,
        isSelectedTop: isSelected && isAltPressed,
        isHoveredTop: isHovered && isAltPressed
      })}
      data-widget-type={widget.content.type}
      id={id}
      key={id}
      ref={widgetRef}
      style={{
        width: positionLimits.isAutoWidth ? 'auto' : currentPosition.width,
        minWidth: positionLimits.minWidth ? positionLimits.minWidth : 0,
        height: positionLimits.isAutoHeight ? 'auto' : currentPosition.height,
        left: currentPosition.x,
        top: currentPosition.y,
        transform: `rotate(${currentPosition.rotate}deg)`
      }}
      onContextMenu={handleContextMenu}
      onMouseEnter={handleHoverObject}
      onMouseLeave={handleUnhoverObject}
    >
      <div className={b('wrapper', { transparent: isEditWidgetMode && isSelected })}>
        {isEditWidgetMode && isSelected && (
          <div
            className={b('outline')}
            style={{
              borderWidth: scale && isEditWidgetMode ? `${scale}px` : '0px'
            }}
          />
        )}
        {renderWidgetControl({
          content,
          position: currentPosition,
          positionLimits,
          elementsSize,
          isEditWidgetMode: isEditWidgetMode && isSelected,
          isAutoplayVideo,
          handleChangheWidgetContent
        })}
      </div>
      {isAltPressed && !isGroupMode && (
        <>
          {isSelected ? (
            <WidgetLines
              hoveredPosition={
                hoveredWidget?.id !== id
                  ? hoveredWidget?.positionByResolutions[`${presetWidth}x${presetHeight}`]
                  : undefined
              }
              presetHeight={presetHeight}
              presetWidth={presetWidth}
              selectedPosition={currentPosition}
            />
          ) : (
            isHovered && hasSelected && <WidgetOutline position={currentPosition} />
          )}
        </>
      )}
    </div>
  );
});

export default WidgetFactory;
