import React, { useEffect, useState } from 'react';
import block from 'bem-cn';
import {
  STAT_WIDGETS_TYPES,
  Story,
  StoryAnalyticsGeneral,
  WidgetAnalyticsAnswers,
  fetchAnalyticsStoryById
} from '@features';
import { useParams } from 'react-router-dom';
import { RootState, useAppDispatch } from '@store';
import { t } from 'i18next';
import { useSelector } from 'react-redux';
import Skeleton from 'react-loading-skeleton';
import { checkIsInteractiveWidgetsExists } from '@features/storiesAnalyticsFeature/utils';
import { WidgetObjectType } from '@storysdk/react';
import { getAnswerVariantsData } from '@utils';
import './StoryAnalyticsCard.scss';

const b = block('StoryAnalyticsCard');

interface StoryAnalyticsCardProps {
  story: Story;
  isLoading?: boolean;
  onOpenModal?(storyId: string): void;
}

type WidgetsCountType = {
  [key: string]: number;
};

export const StoryAnalyticsCard: React.FC<StoryAnalyticsCardProps> = ({
  story,
  isLoading,
  onOpenModal
}) => {
  const [widgets, setWidgets] = useState<WidgetObjectType[]>([]);
  const { appId, groupId } = useParams<{ appId: string; groupId?: string }>();
  const [widgetsCount, setWidgetsCount] = useState<WidgetsCountType>({});
  const [widgetNumbers, setWidgetNumbers] = useState<WidgetsCountType>({});

  const storyData = useSelector(
    (store: RootState) => store.storiesAnalyticsGroup.stories[story.id]
  );

  const storiesLoadingStatuses = useSelector(
    (store: RootState) => store.storiesAnalyticsGroup.storiesLoadingStatuses
  );

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (appId && groupId && groupId !== 'all' && !storiesLoadingStatuses[story.id] && !isLoading) {
      dispatch(fetchAnalyticsStoryById({ appId, groupId, storyId: story.id }));
    }
  }, []);

  useEffect(() => {
    if (storyData && storyData.widgets.length) {
      const widgetsFiltered = [...storyData.widgets].filter((widget) =>
        STAT_WIDGETS_TYPES.find((type) => type === widget.content.type)
      );

      setWidgets(widgetsFiltered);
    }
  }, [storyData]);

  useEffect(() => {
    const widgetCount = {} as WidgetsCountType;
    const widgetsNumbers = {} as WidgetsCountType;

    widgets.forEach((widget) => {
      if (!widgetCount[widget.content.type]) {
        widgetsNumbers[widget.id] = 1;
        widgetCount[widget.content.type] = 1;
      } else {
        widgetsNumbers[widget.id] = widgetCount[widget.content.type] + 1;
        widgetCount[widget.content.type] += 1;
      }
      setWidgetsCount(widgetCount);
      setWidgetNumbers(widgetsNumbers);
    });
  }, [widgets]);

  return (
    <div className={b()}>
      <StoryAnalyticsGeneral
        isLoading={isLoading}
        story={story}
        onMoreClick={() => onOpenModal && onOpenModal(story.id)}
      />
      <div className={b('widgetsList')}>
        {isLoading || storiesLoadingStatuses[story.id] ? (
          <>
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="100%" />
            <Skeleton height={20} width="100%" />
          </>
        ) : (
          <>
            {checkIsInteractiveWidgetsExists(widgets) ? (
              <>
                {widgets.map((widget) => (
                  <WidgetAnalyticsAnswers
                    answers={getAnswerVariantsData(
                      widget.content.type,
                      storyData.statistic?.interactions[widget.id]?.variants,
                      storyData.statistic?.interactions[widget.id]?.reactions,
                      widget.content.params
                    )}
                    isShowNumber={widgetsCount[widget.content.type] > 1}
                    key={`answesWidget-${widget.id}`}
                    widget={widget}
                    widgetNumber={widgetNumbers[widget.id]}
                  />
                ))}
              </>
            ) : (
              <div className={b('noData')}>
                <p className={b('noDataTitle')}>{t('dashboard.analytics.emptyTitle')}</p>
                <p className={b('noDataText')}>{t('dashboard.analytics.emptyText')}</p>
              </div>
            )}
          </>
        )}
      </div>
    </div>
  );
};
